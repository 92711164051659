<div *ngIf="alert === 'OK'" class="alert alert-success alert-pgs">
  {{alertMessage}}
</div>
<div *ngIf="alert === 'KO'" class="alert alert-danger alert-pgs" role="alert">
  {{alertMessage}}
</div>
<div class="body">
  <br>
  <div>
    <div>
      <mat-expansion-panel expanded>
        <mat-expansion-panel-header>
          <mat-panel-description> Provision EMV Media</mat-panel-description>
        </mat-expansion-panel-header>
        <div class="container">
          <form
            class="form-horizontal">
            <div class="form-group">
              <input [(ngModel)]="form.merchantId" hidden="true" matInput name="merchantId">
              <input [(ngModel)]="form.surrogatePAN" hidden="true" matInput name="surrogatePAN">
              <input [(ngModel)]="params.tokenizationSessionId" hidden="true" matInput name="tokenizationSessionId">
              <div class="container">
                <div class="row mt-4">
                  <div class="col-10">
                    <div class="card border-dark">
                      <div class="card-header">
                        PGS Payment gatway web tokenizer
                      </div>

                      <div class="card-body ">
                        <h4 class="card-title">PAYMENT INFORMATION</h4>

                        <p class="card-text">Transaction reference:
                          <strong>f756b466-6155-4909-aa96-e8b8145ad22f@021474f3-01d9-46bb-9557-109047b0370b</strong>
                        </p>
                        <p class="card-text">Order value: <strong>0 EUR</strong></p>
                        <p class="card-text">Merchant identifier: <strong>***TEST*** BEATS</strong></p>
                        <h4 class="card-title">PAYMENT DATA</h4>
                        <p class="card-text">Please enter your payment data</p>

                        <div class="container">
                          <div class="form-group">
                            <label for="card-number">Card number*</label>
                            <input
                              [(ngModel)]="form.cardNumber"
                              [maxLength]="form.hash === 'none' ? 200 : 16 "
                              aria-describedby="helpId"
                              class="form-control"
                              #creditCardNumber (keydown)="checkLength($event, creditCardNumber, 16, 9999999999999999)"
                              id="card-number" name="creditCardNumber" placeholder="Debit/Credit Card Number"
                              required="true" type="text"
                              (ngModelChange)="updateSurrogatePan()">
                          </div>

                          <div class="row">
                            <div class="col">
                              <div class="form-group">
                                <label for="ExpiryMonth">Expiry date (MM/YYYY)</label>
                                <select [(ngModel)]="form.expiryMonth" class="form-control" id="ExpiryMonth"
                                        name="ExpiryMonth">
                                  <option value="0" selected>01</option>
                                  <option value="1">02</option>
                                  <option value="2">03</option>
                                  <option value="3">04</option>
                                  <option value="4">05</option>
                                  <option value="5">06</option>
                                  <option value="6">07</option>
                                  <option value="7">08</option>
                                  <option value="8">09</option>
                                  <option value="9">10</option>
                                  <option value="10">11</option>
                                  <option value="11">12</option>
                                </select>
                              </div>
                            </div>
                            <div class="col">
                              <div class="form-group">

                                <label for="ExpiryYear">&nbsp;</label>
                                <select [(ngModel)]="form.expiryYear" class="form-control" id="ExpiryYear"
                                        name="ExpiryYear">
                                  <option value="{{form.yearMinus4}}">{{form.yearMinus4}}</option>
                                  <option value="{{form.yearMinus3}}">{{form.yearMinus3}}</option>
                                  <option value="{{form.yearMinus2}}">{{form.yearMinus2}}</option>
                                  <option value="{{form.yearMinus1}}">{{form.yearMinus1}}</option>
                                  <option value="{{form.currentYear}}" selected>{{form.currentYear}}</option>
                                  <option value="{{form.year1}}">{{form.year1}}</option>
                                  <option value="{{form.year2}}">{{form.year2}}</option>
                                  <option value="{{form.year3}}">{{form.year3}}</option>
                                  <option value="{{form.year4}}">{{form.year4}} </option>
                                  <option value="{{form.year5}}">{{form.year5}} </option>
                                  <option value="{{form.year6}}">{{form.year6}} </option>
                                  <option value="{{form.year7}}">{{form.year7}} </option>
                                  <option value="{{form.year8}}">{{form.year8}} </option>
                                </select>
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <label for="cvv">CVV</label>
                            <input
                              [(ngModel)]="form.cvv"
                              class="form-control" id="cvv" name="cvv" placeholder=""
                              type="password" #cvv (keydown)="checkLength($event, cvv, 3, 999)" required="true" autocomplete="off">
                          </div>
                          <div class="form-group">
                            <label for="paymentSchemeIdentifier">Payment Scheme</label>
                            <select [(ngModel)]="form.paymentSchemeIdentifier" class="form-control"
                                    id="paymentSchemeIdentifier"
                                    name="PaymentScheme">
                              <option [value]="paymentScheme.PaymentSchemeIdentifier"
                                      *ngFor="let paymentScheme of emvAIDTable">{{paymentScheme.AID}} <=> {{paymentScheme.PaymentSchemeIdentifier}}</option>

                            </select>
                          </div>
                          <div class="form-group">
                            <label for="cardSubType">Card sub type</label>
                            <select [(ngModel)]="form.cardSubType" class="form-control"
                                    id="cardSubType"
                                    name="CardSubType">
                              <option [value]="cardSubType.Type"
                                      *ngFor="let cardSubType of cardSubTypeTable">{{cardSubType.Type}}</option>

                            </select>
                          </div>
                          <div class="form-group">
                            <label for="issuer-country-code">Payment account reference</label>
                            <input
                              [(ngModel)]="form.paymentAccountReference"
                              class="form-control" id="payment-account-reference" name="paymentAccountReference" placeholder=""
                              #inputText type="text"
                              required="true">
                          </div>
                          <div class="form-group">
                            <label for="issuer-country-code">Issuer Country Code</label>
                            <input
                              [(ngModel)]="form.issuerCountryCode"
                              class="form-control" id="issuer-country-code" name="issuerCountryCode" placeholder=""
                              #inputText type="text" (keydown)="checkLength($event, inputText, 3, 999)"
                              required="true">
                          </div>
                          <div class="form-group">
                            <label for="ExpiryMonth">Transaction Currency Code</label>
                            <select [(ngModel)]="form.transactionCurrencyCode" class="form-control"
                                    id="TransactionCurrencyCode"
                                    name="TransactionCurrencyCode" required="true">
                              <option value="784">AED</option>
                              <option value="971">AFN</option>
                              <option value="8">ALL</option>
                              <option value="51">AMD</option>
                              <option value="532">ANG</option>
                              <option value="973">AOA</option>
                              <option value="32">ARS</option>
                              <option value="36">AUD</option>
                              <option value="533">AWG</option>
                              <option value="944">AZN</option>
                              <option value="977">BAM</option>
                              <option value="52">BBD</option>
                              <option value="50">BDT</option>
                              <option value="975">BGN</option>
                              <option value="48">BHD</option>
                              <option value="108">BIF</option>
                              <option value="60">BMD</option>
                              <option value="96">BND</option>
                              <option value="68">BOB</option>
                              <option value="984">BOV</option>
                              <option value="986">BRL</option>
                              <option value="44">BSD</option>
                              <option value="64">BTN</option>
                              <option value="72">BWP</option>
                              <option value="974">BYR</option>
                              <option value="84">BZD</option>
                              <option value="124">CAD</option>
                              <option value="756">CHF</option>
                              <option value="152">CLP</option>
                              <option value="156">CNY</option>
                              <option value="170">COP</option>
                              <option value="188">CRC</option>
                              <option value="931">CUC</option>
                              <option value="192">CUP</option>
                              <option value="132">CVE</option>
                              <option value="203">CZK</option>
                              <option value="262">DJF</option>
                              <option value="208">DKK</option>
                              <option value="214">DOP</option>
                              <option value="12">DZD</option>
                              <option value="818">EGP</option>
                              <option value="232">ERN</option>
                              <option value="230">ETB</option>
                              <option value="978">EUR</option>
                              <option value="242">FJD</option>
                              <option value="238">FKP</option>
                              <option value="826">GBP</option>
                              <option value="981">GEL</option>
                              <option value="936">GHS</option>
                              <option value="292">GIP</option>
                              <option value="270">GMD</option>
                              <option value="324">GNF</option>
                              <option value="320">GTQ</option>
                              <option value="328">GYD</option>
                              <option value="344">HKD</option>
                              <option value="340">HNL</option>
                              <option value="191">HRK</option>
                              <option value="332">HTG</option>
                              <option value="348">HUF</option>
                              <option value="360">IDR</option>
                              <option value="376">ILS</option>
                              <option value="356">INR</option>
                              <option value="368">IQD</option>
                              <option value="364">IRR</option>
                              <option value="352">ISK</option>
                              <option value="388">JMD</option>
                              <option value="400">JOD</option>
                              <option value="392">JPY</option>
                              <option value="404">KES</option>
                              <option value="417">KGS</option>
                              <option value="116">KHR</option>
                              <option value="174">KMF</option>
                              <option value="408">KPW</option>
                              <option value="410">KRW</option>
                              <option value="414">KWD</option>
                              <option value="136">KYD</option>
                              <option value="398">KZT</option>
                              <option value="418">LAK</option>
                              <option value="422">LBP</option>
                              <option value="144">LKR</option>
                              <option value="430">LRD</option>
                              <option value="434">LYD</option>
                              <option value="504">MAD</option>
                              <option value="498">MDL</option>
                              <option value="969">MGA</option>
                              <option value="807">MKD</option>
                              <option value="104">MMK</option>
                              <option value="496">MNT</option>
                              <option value="446">MOP</option>
                              <option value="478">MRO</option>
                              <option value="480">MUR</option>
                              <option value="462">MVR</option>
                              <option value="454">MWK</option>
                              <option value="484">MXN</option>
                              <option value="458">MYR</option>
                              <option value="943">MZN</option>
                              <option value="516">NAD</option>
                              <option value="566">NGN</option>
                              <option value="558">NIO</option>
                              <option value="578">NOK</option>
                              <option value="524">NPR</option>
                              <option value="554">NZD</option>
                              <option value="512">OMR</option>
                              <option value="590">PAB</option>
                              <option value="604">PEN</option>
                              <option value="598">PGK</option>
                              <option value="608">PHP</option>
                              <option value="586">PKR</option>
                              <option value="985">PLN</option>
                              <option value="600">PYG</option>
                              <option value="634">QAR</option>
                              <option value="946">RON</option>
                              <option value="941">RSD</option>
                              <option value="643">RUB</option>
                              <option value="646">RWF</option>
                              <option value="682">SAR</option>
                              <option value="90">SBD</option>
                              <option value="690">SCR</option>
                              <option value="938">SDG</option>
                              <option value="752">SEK</option>
                              <option value="702">SGD</option>
                              <option value="694">SLL</option>
                              <option value="706">SOS</option>
                              <option value="968">SRD</option>
                              <option value="728">SSP</option>
                              <option value="678">STD</option>
                              <option value="760">SYP</option>
                              <option value="748">SZL</option>
                              <option value="764">THB</option>
                              <option value="972">TJS</option>
                              <option value="934">TMT</option>
                              <option value="788">TND</option>
                              <option value="776">TOP</option>
                              <option value="949">TRY</option>
                              <option value="780">TTD</option>
                              <option value="901">TWD</option>
                              <option value="834">TZS</option>
                              <option value="980">UAH</option>
                              <option value="800">UGX</option>
                              <option value="840">USD</option>
                              <option value="858">UYU</option>
                              <option value="860">UZS</option>
                              <option value="937">VEF</option>
                              <option value="704">VND</option>
                              <option value="548">VUV</option>
                              <option value="882">WST</option>
                              <option value="950">XAF</option>
                              <option value="951">XCD</option>
                              <option value="952">XOF</option>
                              <option value="953">XPF</option>
                              <option value="886">YER</option>
                              <option value="710">ZAR</option>
                              <option value="967">ZMW</option>
                              <option value="932">ZWL</option>
                            </select>
                          </div>
                          <div class="custom-control custom-switch">
                            <input aria-controls="collapseExample" aria-expanded="false"
                                   class="custom-control-input"
                                   data-target="#collapseExample" data-toggle="collapse" id="customSwitch1"
                                   (change)="this.changeSwitch()"
                                   type="checkbox">
                            <label class="custom-control-label" for="customSwitch1">Emulate several security
                              token</label>
                          </div>
                          <div class="collapse" id="collapseExample">
                            <div class="card card-body">
                              <div class="form-group">
                                <label>Security token</label>
                                <input
                                  [(ngModel)]="form.additionalSecurityTokens[0]"
                                  aria-describedby="helpId"
                                  class="form-control"
                                  id="additionalSecurityTokens0"
                                  name="additionalSecurityTokens"
                                  type="text">
                                <input
                                  [(ngModel)]="form.additionalSecurityTokens[1]"
                                  aria-describedby="helpId"
                                  class="form-control"
                                  id="additionalSecurityTokens1"
                                  name="additionalSecurityTokens"
                                  type="text">
                                <input
                                  [(ngModel)]="form.additionalSecurityTokens[2]"
                                  aria-describedby="helpId"
                                  class="form-control"
                                  id="additionalSecurityTokens2"
                                  name="additionalSecurityTokens"
                                  type="text">
                                <input
                                  [(ngModel)]="form.additionalSecurityTokens[3]"
                                  aria-describedby="helpId"
                                  class="form-control"
                                  id="additionalSecurityTokens3"
                                  name="additionalSecurityTokens"
                                  type="text">
                                <input
                                  [(ngModel)]="form.additionalSecurityTokens[4]"
                                  aria-describedby="helpId"
                                  class="form-control"
                                  id="additionalSecurityTokens4"
                                  name="additionalSecurityTokens"
                                  type="text">
                              </div>
                            </div>
                          </div>
                          <div class="form-group">
                            <div class="col-sm-offset-2 col-sm-10">
                              <div class="row">
                                <button type="button" mat-raised-button color="primary"
                                        [disabled]="!(form.cardNumber && form.issuerCountryCode && form.cvv )"
                                        (click)="provisionMedia();">Provision
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </mat-expansion-panel>
    </div>
  </div>
</div>

