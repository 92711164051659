import { CollectionViewer } from "@angular/cdk/collections";
import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, of } from "rxjs";
import { ApiHttpService } from "../core/api-http.service";
import { catchError, finalize } from 'rxjs/operators';


export class EventLogDataSource implements DataSource<any> {

  private eventLogsSubject = new BehaviorSubject<any[]>([]);
  private totalElements;
  private loadingSubject = new BehaviorSubject<boolean>(false);
  public loading$ = this.loadingSubject.asObservable();

  constructor(private httpService: ApiHttpService) {
  }

  connect(httpService: CollectionViewer): Observable<any[]> {
    return this.eventLogsSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.eventLogsSubject.complete();
    this.loadingSubject.complete();
  }

  loadEventLogs(
    correlationId?: string,
    card?: string,
    origin?: string,
    state?: string,
    start?: Date,
    end?: Date,
    paymentOperationType?: string,
    pageIndex = 0,
    pageSize = 10) {

    this.loadingSubject.next(true);

    this.httpService.searchEventLogs(
      correlationId,
      card,
      origin,
      state,
      start,
      end,
      paymentOperationType,
      pageIndex,
      pageSize
    ).pipe(
      catchError(() => of([])),
      finalize(() => this.loadingSubject.next(false))
    )
      .subscribe(eventlogs => {
        this.eventLogsSubject.next(eventlogs.content);
        this.totalElements = eventlogs.page.totalElements;
      });
  }

  total() {
    return this.totalElements;
  }

  loadEventLogsAll(pageIndex = 0, pageSize = 10) {

    this.loadingSubject.next(true);

    this.httpService.searchEventLogs(null, null, null, null, null, null, null, pageIndex, pageSize)
      .pipe(
        catchError(() => of([])),
        finalize(() => this.loadingSubject.next(false))
      )
      .subscribe(eventlogs => this.eventLogsSubject.next(eventlogs));
  }
}
