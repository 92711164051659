// Angular Modules
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Configuration } from '../models/Configuration';
import { config } from '../app.config';
import * as moment from 'moment';
import { Observable } from "rxjs";
import { Token } from "../models/Token";
import { DebtRecoveryRequest } from "../models/DebtRecoveryRequest";

@Injectable({providedIn: 'root'})
export class ApiHttpService {

  environment: any;

  constructor(private http: HttpClient) {
    this.environment = {
      apiUrl: config.API_URL + "pgs",
      acmUrl: config.API_URL + "acm"
    }
  }

  getAllConfigurations() {
    console.log(`${this.environment.apiUrl}/simulator/config/all`);
    return this.http.get<any>(`${this.environment.apiUrl}/simulator/config/all`);
  }

  getConfigurationByRequestType(requestType) {
    return this.http.get<any>(`${this.environment.apiUrl}/simulator/config/${requestType}`);
  }

  updateConfiguration(data) {
    return this.http.post<Configuration>(`${this.environment.apiUrl}/simulator/config`, data);
  }

  processPaymentAuthorization(data) {
    return this.http.post<any>(`${this.environment.apiUrl}/processAuthorization/process`, data);
  }

  processDebtRecovery(debtRecoveryRequest: DebtRecoveryRequest) {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    const params: {
      seqId?: string,
      amount?: string,
      responseStatus?: string
      approvalCode?: string
      authorizationCode?: string,
      responseCode?: string,
    } = {
      seqId: debtRecoveryRequest.seqId.toString(),
      amount: debtRecoveryRequest.amount.toString(),
      responseStatus: debtRecoveryRequest.responseStatus,
      approvalCode: debtRecoveryRequest.approvalCode,
      authorizationCode: debtRecoveryRequest.authorizationCode,
      responseCode: debtRecoveryRequest.responseCode.toString()
    };

    return this.http.get<any>(`${this.environment.apiUrl}/processDebtRecovery/process?`, {
      params: params,
      headers: headers
    });
  }


  process(data) {
    return this.http.post<any>(`${this.environment.apiUrl}/paymentData/process`, data);
  }

  getOrCreateSecurityToken(data) {
    const headers = new HttpHeaders().set('skip', 'true');
    return this.http.post<any>(`${this.environment.apiUrl}/paymentData/securityToken`, data, {headers});
  }

  getDenyListVersion(pageIndex, pageSize, version?: string, creationDate?: string) {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    let params: {
      version?: string,
      creationDate?: string,
      size: string,
      page: string
    } = {size: pageSize + "", page: pageIndex + ""};

    if (version)
      params.version = version;

    if (creationDate)
      params.creationDate = creationDate;

    return this.http.get<any>(`${this.environment.apiUrl}/denyLists/search/versions?`, {
      params: params,
      headers: headers
    });
  }

  getDenyMediaList(pageIndex, pageSize, surrogatePAN?: string, truncatedPan?: string) {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    let params: {
      surrogatePan?: string,
      truncatedPan?: string,
      securityToken?: string,
      size: string,
      page: string
    } = {size: pageSize + "", page: pageIndex + ""};

    if (surrogatePAN) {
      params.surrogatePan = surrogatePAN;
    }

    if (truncatedPan) {
      params.truncatedPan = truncatedPan;
    }

    return this.http.get<any>(`${this.environment.apiUrl}/denyLists/internal/search?`, {
      params: params,
      headers: headers
    });
  }

  searchEventLogs(
    correlationId?: string,
    card?: string,
    origin?: string,
    state?: string,
    start?: Date,
    end?: Date,
    paymentOperationType?: string,
    pageIndex: number = 0,
    pageSize: number = 10) {

    const headers = new HttpHeaders().set('Accept', 'application/json');
    let params: {
      size: string,
      page: string,
      sort: string,
      correlationId?: string,
      card?: string,
      state?: string,
      origin?: string,
      startDateTime?: string,
      endDateTime?: string,
      paymentOperationType?: string,
    } = {size: pageSize + "", page: pageIndex + "", sort: "tapDateTime,desc"};

    if (correlationId) {
      params.correlationId = correlationId;
    }

    if (card) {
      params.card = card;
    }

    if (origin) {
      params.origin = origin;
    }

    if (state) {
      params.state = state;
    }

    if (start) {
      params.startDateTime = moment.utc(start).toISOString();
    }

    if (end) {
      params.endDateTime = moment.utc(end).toISOString();
    }

    if (paymentOperationType) {
      params.paymentOperationType = paymentOperationType;
    }

    return this.http.get<any>(`${this.environment.apiUrl}/eventlog/search?`, {params: params, headers: headers});
  }

  searchAcceptList(surrogatePan?: string, truncatedPan?: string, pageIndex: number = 0, pageSize: number = 10) {
    const headers = new HttpHeaders().set('Accept', 'application/json');
    let params: {
      size: string,
      page: string,
      sort: string,
      surrogatePan?: string,
      truncatedPan?: string

    } = {size: pageSize + "", page: pageIndex + "", sort: ""};
    if (surrogatePan) {
      params.surrogatePan = surrogatePan;
    }
    if (truncatedPan) {
      params.truncatedPan = surrogatePan;
    }
    return this.http.get<any>(`${this.environment.apiUrl}/acceptList/search?`, {params: params, headers: headers});

  }

  login(authorization) {
    let params = {
      grant_type: 'password',
      realm: "backoffice"
    };

    let headers = {
      'content-type': 'application/x-www-form-urlencoded',
      Authorization: authorization,
      Accept: "application/json, text/plain, */*",
      skip: "true",
    }

    return this.http.post<any>(`${this.environment.acmUrl}/login`, null, {params: params, headers: headers});
  }

  refreshToken(refreshToken): Observable<Token> {

    const headers = {
      'Content-Type': 'application/x-www-form-urlencoded',
      skip: "true"
    };

    const params = {
      grant_type: 'refresh_token',
      refresh_token: refreshToken
    };

    return this.http.post<any>(`${this.environment.acmUrl}/login`, null, {
      params: params,
      headers: headers
    })
  }

}
