import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { ApiHttpService } from '../core/api-http.service';
import { Configuration } from '../models/Configuration';
import { Mode } from '../models/Modes';
import { EventLogDataSource } from '../services/EventLogDataSource';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { fromEvent } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import * as currencyCodes from 'currency-codes';
import { PaymentAuthorization } from '../models/PaymentAuthorization';
import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import {
  MomentDateTimeAdapter
} from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { DebtRecoveryRequest } from '../models/DebtRecoveryRequest';
import { PaymentAuthorizationResponseStatus } from '../models/PaymentAuthorizationResponseStatus';

export const MY_CUSTOM_FORMATS = {
  fullPickerInput: 'DD-MM-YYYY HH:mm:ss',
  parseInput: 'YYYY-MM-DD HH:mm:ss',
  datePickerInput: 'YYYY-MM-DD HH:mm:ss',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'YYMMDD',
    monthYearLabel: 'YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    {provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE]},
    {provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS},
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
  ],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class HomeComponent implements OnInit {

  dataSource: EventLogDataSource;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  @ViewChild('input', {static: true}) input: ElementRef;
  paymentOperationTypes: string[] = [
    null,
    "AUTHORIZATION",
    "DEBIT",
    "NOMINAL_AUTHORIZATION",
    "ORDER_CHARGE",
    "REFUND",
    "REVERSAL"
  ]

  selectedEvent: any;
  selectedMoment: any;
  expiryDate: any;
  start = null;
  end = null;
  search = '';
  alert = '';
  alertMessage = '';
  dialogRef: any;
  correlationId: string;
  paymentSchemeIdentification: string;
  paymentAuthorization: PaymentAuthorization = new PaymentAuthorization();
  cardUpdated = false;
  maskedPAN: string;
  issuerCountryCode: string;
  transactionCurrencyCode: string;
  applicationPrimaryAccountSequenceNumber: number;
  @ViewChild(MatDatepicker) datepicker: MatDatepicker<Date>;
  interval: any;
  expandedElement: any | null = null;
  total: number;
  modes: Mode[] = [
    {label: 'Manual', value: 'MANUAL'},
    {label: 'Immediate', value: 'IMMEDIATE'},
    {label: 'Delayed', value: 'DELAYED'},
    {label: 'Server failure', value: 'FAILURE'},
  ];
  allConfigurations: Map<string, Configuration>;
  configurations: Configuration[] = [];
  displayedColumns = ['Date time', 'Origine', 'Type', 'OperationType', 'correlationId', 'Card', 'Amount', 'State', 'Action'];
  displayedColumnFilters: string[] = [
    'filter'
  ];
  debtRecovery: DebtRecoveryRequest = new DebtRecoveryRequest();
  selectedPaymentOperationType: string;

  constructor(private httpService: ApiHttpService, private dialog: MatDialog, private readonly adapter: DateAdapter<Date>) {
    this.adapter.setLocale('GB');
  }

  changeRange() {

    this.start = this.selectedMoment[0];
    this.end = this.selectedMoment[1];
    if (this.end == null) {
      this.failAlert('please specify two dates to be able to search by range dates');
    } else {

      this.paginator.pageIndex = 0;
      let filter = this.search;
      this.dataSource.loadEventLogs(
        filter,
        filter,
        filter,
        filter,
        this.start,
        this.end,
        this.selectedPaymentOperationType,
        this.paginator.pageIndex,
        this.paginator.pageSize
      )
    }
  }

  selectedPaymentOperationTypeChange(selectedPaymentOperationType:string) {
    this.selectedPaymentOperationType = selectedPaymentOperationType;
    this.searchByFilter();
  }

  searchByFilter() {
    this.paginator.pageIndex = 0;
    let filter = this.search;
    this.dataSource.loadEventLogs(
      filter,
      filter,
      filter,
      filter,
      this.start,
      this.end,
      this.selectedPaymentOperationType,
      this.paginator.pageIndex,
      this.paginator.pageSize
    )
  }

  ngOnInit(): void {


    this.dataSource = new EventLogDataSource(this.httpService);
    this.dataSource.loadEventLogs(null, null, null, null, null, null, null,0, 10);
    this.total = this.dataSource.total();
    this.paginator.page
      .pipe(
        tap(() => this.dataSource.loadEventLogs(null, null, null, null, null, null, null, this.paginator.pageIndex, this.paginator.pageSize))
      )
      .subscribe();

    fromEvent(this.input.nativeElement, 'keyup')
      .pipe(
        debounceTime(150),
        distinctUntilChanged(),
        tap(() => {
          this.searchByFilter();
        })
      )
      .subscribe();

    // to create different configurations if not exists
    this.httpService.getConfigurationByRequestType('PAYMENT_DATA').pipe()
      .subscribe((data) => {
        this.httpService.getConfigurationByRequestType('SECURITY_TOKEN').pipe()
          .subscribe((data) => {
            this.httpService.getConfigurationByRequestType('PAYMENT_AUTHORIZATION').pipe()
              .subscribe((data) => {
                this.httpService.getConfigurationByRequestType('SETTLEMENT').pipe()
                  .subscribe((data) => {
                    this.httpService.getConfigurationByRequestType('DEBT_RECOVERY').pipe()
                      .subscribe((data) => {

                      });
                  });
              });
          });
      });

    this.httpService
      .getAllConfigurations()
      .pipe()
      .subscribe(
        (data) => {
          this.allConfigurations = data.configurations;
          if (data.configurations["PAYMENT_DATA"]) {
            if (data.configurations["PAYMENT_DATA"].mode === 'FAILURE') {
              data.configurations["PAYMENT_DATA"].failure = true;
            } else {
              data.configurations["PAYMENT_DATA"].failure = false;
            }

            this.configurations.push(data.configurations["PAYMENT_DATA"]);

          }
          if (data.configurations["SECURITY_TOKEN"]) {
            let duration = moment.duration(data.configurations["SECURITY_TOKEN"].duration).asMinutes();
            data.configurations["SECURITY_TOKEN"].duration = duration;
            this.configurations.push(data.configurations["SECURITY_TOKEN"]);
          }
          if (data.configurations["PAYMENT_AUTHORIZATION"]) {
            let duration = moment.duration(data.configurations["PAYMENT_AUTHORIZATION"].duration).asMinutes();
            data.configurations["PAYMENT_AUTHORIZATION"].duration = duration
            this.configurations.push(data.configurations["PAYMENT_AUTHORIZATION"]);
          }
          if (data.configurations["SETTLEMENT"]) {
            let duration = moment.duration(data.configurations["SETTLEMENT"].duration).asMinutes();
            data.configurations["SETTLEMENT"].duration = duration
            this.configurations.push(data.configurations["SETTLEMENT"]);
          }
          if (data.configurations["DEBT_RECOVERY"]) {
            let duration = moment.duration(data.configurations["DEBT_RECOVERY"].duration).asMinutes();
            data.configurations["DEBT_RECOVERY"].duration = duration;
            this.configurations.push(data.configurations["DEBT_RECOVERY"]);
          }
        },
        (error) => {

        }
      );
  }

  radioChange(event, configuration) {
    configuration.mode = event.value.value;
    //this.allConfigurations[configuration.requestType] = configuration;

  }

  updateConfiguration(event, configuration) {

    let duration = configuration.duration;
    configuration.duration = moment.duration(duration, 'minutes');
    if (configuration.failure === true) {
      configuration.mode = 'FAILURE'
    } else if (configuration.failure === false) {
      configuration.mode = 'IMMEDIATE'
    }
    this.allConfigurations[configuration.requestType] = configuration;
    let configurationToUpdate: Map<string, Configuration> = new Map();
    configurationToUpdate[configuration.requestType] = configuration;
    this.httpService.updateConfiguration({configurations: configurationToUpdate}).pipe()
      .subscribe(
        (data) => {
          configuration.duration = duration;
          this.successAlert('Configuration Updated successfully')
        },
        (error) => {
          this.failAlert('Error Updating Configuration')
        }
      );

  }

  clearAlert() {
    this.alert = '';
  }

  successAlert(message) {
    this.alert = 'OK';
    this.alertMessage = message;
    setTimeout(() => {
      this.clearAlert();
    }, 2000);
  }

  failAlert(message) {
    this.alert = 'KO';
    this.alertMessage = message;
    setTimeout(() => {
      this.clearAlert();
    }, 2000);
  }

  openDialog(event, content) {
    this.correlationId = event.correlationId;

    this.paymentSchemeIdentification = event.content.paymentSchemeIdentification;

    this.maskedPAN = event.truncatedPan;

    this.cardUpdated = false;
    this.selectedEvent = event;
    this.dialogRef = this.dialog.open(content);


  }

  openPaymentAuthorizationDialog(event, content) {
    this.paymentAuthorization.seqId = event.seqId;
    this.paymentAuthorization.amount = event.content.amount;
    this.paymentAuthorization.responseCode = '100';
    this.paymentAuthorization.capturePossible = 'false';

    this.selectedEvent = event;
    this.dialogRef = this.dialog.open(content);
  }

  openDebtRecoveryDialog(event, content): void {
    this.debtRecovery.seqId = event.seqId;
    this.debtRecovery.amount = event.amount;
    this.debtRecovery.responseStatus = PaymentAuthorizationResponseStatus.APPROVED;
    this.debtRecovery.responseCode = '100';


    this.selectedEvent = event;
    this.dialogRef = this.dialog.open(content);
  }

  isNumeric(value) {
    return /^\d+$/.test(value);
  }


  validCard(card) {

    if (!card) {
      return false;
    }
    if (card.length != 14 && card.length < 15)
      return false;

    if (!this.isNumeric(card.substring(0, 4)) || !this.isNumeric(card.substring(12, 16))) {
      return false;
    }
    if (card.substring(4, 12) != '********') {
      return false;
    }
    return true;
  }

  processPaymentAuthorization() {
    if (!this.paymentAuthorization.responseStatus || this.paymentAuthorization.responseStatus === '-') {
      this.failAlert('Please choose a response status ');
      return;
    }

    if (this.paymentAuthorization.responseStatus === 'PARTIALLY_APPROVED' && (!this.paymentAuthorization.amount || this.paymentAuthorization.amount >= this.selectedEvent.content.amount)) {
      this.failAlert('amount should be lower then ' + this.selectedEvent.content.amount);
      return;
    }

    this.httpService.processPaymentAuthorization(this.paymentAuthorization).pipe()
      .subscribe(
        (data) => {
          this.successAlert('Authorization Processed successfully');
          this.searchByFilter();
        },
        (error) => {
          this.failAlert('Error while processing authorization');
        }
      );
    this.maskedPAN = null;
    this.paymentSchemeIdentification = null;
    this.dialogRef.close();
  }

  processDebtRecovery() {
    if (this.isPartiallyApprouvedAmountValid()) {
      this.failAlert('Amount should be lower than ' + this.selectedEvent.content.amount);
      return;
    }

    this.httpService.processDebtRecovery(this.debtRecovery).pipe()
      .subscribe(
        (data) => {
          this.successAlert('Debt Recovery processes successfully');
          this.searchByFilter()
        },
        (error) => {
          this.failAlert('Error while processing debt recovery');
        }
      );
    this.dialogRef.close();
  }

  isPartiallyApprouvedAmountValid(): boolean {
    return this.debtRecovery.responseStatus === 'PARTIALLY_APPROVED' && (!this.debtRecovery.amount || this.debtRecovery.amount < this.selectedEvent.content.amount);
  }

  process() {
    let maskedPan = '';
    if (this.maskedPAN === this.selectedEvent.truncatedPan && this.maskedPAN != '-') {
      maskedPan = this.selectedEvent.truncatedPan;
    } else if (this.maskedPAN != this.selectedEvent.truncatedPan && this.validCard(this.maskedPAN)) {
      maskedPan = this.maskedPAN;
    } else if (!this.validCard(this.maskedPAN)) {
      this.failAlert('Wrong Credit card number ');
      return;
    }

    if (this.paymentSchemeIdentification === '-') {
      this.failAlert('Please choose a Card Type ');
      return;
    }
    if (this.issuerCountryCode && !this.isNumeric(this.issuerCountryCode)) {
      this.failAlert('Issuer Country code should be number ');
      return;
    }

    if (this.applicationPrimaryAccountSequenceNumber && !this.isNumeric(this.applicationPrimaryAccountSequenceNumber)) {
      this.failAlert('Application primary Account sequence Number should be number ');
      return;
    }

    let data: {
      correlationId?: string,
      paymentSchemeIdentification?: string,
      truncatedPan?: string
      issuerCountryCode?: string,
      transactionCurrencyCode?: string,
      applicationPrimaryAccountSequenceNumber?: number,
      expiryDate?: string,

    }

    data = {
      correlationId: this.selectedEvent.correlationId,
      paymentSchemeIdentification: this.paymentSchemeIdentification,
      truncatedPan: this.maskedPAN

    }
    if (this.issuerCountryCode) {
      data.issuerCountryCode = this.issuerCountryCode;
    }

    if (this.applicationPrimaryAccountSequenceNumber) {
      data.applicationPrimaryAccountSequenceNumber = this.applicationPrimaryAccountSequenceNumber;
    }

    if (this.transactionCurrencyCode) {
      data.transactionCurrencyCode = this.transactionCurrencyCode;
    }

    if (this.expiryDate) {
      data.expiryDate = this.expiryDate;
    }

    this.httpService.process(data).pipe()
      .subscribe(
        (data) => {
          this.successAlert('Processed successfully')
          this.searchByFilter();
        },
        (error) => {
          this.failAlert('Error while processing')
        }
      );
    this.maskedPAN = null;
    this.paymentSchemeIdentification = null;
    this.dialogRef.close();
  }

  getCurrencyCode(currencyCodeNumber): string {
    const number = currencyCodes.number(currencyCodeNumber);
    return number ? number.code : currencyCodeNumber;
  }

  paymentOperationTypeChange(paymentOperationType: string) {
    console.log(paymentOperationType);
  }

  lol(event) {
    console.log(event);
  }
}
