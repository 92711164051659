import {Component, OnInit} from "@angular/core";
import {ActivatedRoute} from "@angular/router";
import {ApiHttpService} from "../core/api-http.service";
import {Crypto} from "../services/Crypto";


@Component({
  selector: 'app-provision-emv-media',
  templateUrl: './provisionEMVMedia.component.html',
  styleUrls: ['./provisionEMVMedia.component.scss']
})
export class ProvisionEMVMediaComponent implements OnInit {

  cryptoService: Crypto = new Crypto();
  alert = "";
  alertMessage = "";

  list = [
    'standard', 'securityToken', 'sha256', 'hmacSha256', 'none'
  ];
  emvAIDTable = [
    { "AID": "A00000000305076010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA ELO Credit", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000031010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Debit/Credit (Classic)", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A000000003101001", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Credit", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A000000003101002", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Debit", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000032010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Electron", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000032020", "Vendor": "Visa International", "Country": "United States", "Name": "VISA", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000033010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Interlink", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000034010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Specific", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000035010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Specific", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000036010", "Vendor": "Visa International", "Country": "United States", "Name": "Domestic Visa Cash Stored Value", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000036020", "Vendor": "Visa International", "Country": "United States", "Name": "International Visa Cash Stored Value", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000038002", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Auth, VisaRemAuthen EMV-CAP (DPA)", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000038010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Plus", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000039010", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Loyalty", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A000000003999910", "Vendor": "Visa International", "Country": "United States", "Name": "VISA Proprietary ATM", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A00000000401", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard PayPass", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000041010", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Credit/Debit (Global)", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A00000000410101213", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Credit", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A00000000410101215", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Credit", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000042010", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Specific", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000043010", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Specific", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000043060", "Vendor": "Mastercard International", "Country": "United States", "Name": "Maestro (Debit)", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A000000004306001", "Vendor": "Mastercard International", "Country": "United States", "Name": "Maestro (Debit)", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000044010", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Specific", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000045010", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard Specific", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000046000", "Vendor": "Mastercard International", "Country": "United States", "Name": "Cirrus", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000048002", "Vendor": "Mastercard International", "Country": "United States", "Name": "SecureCode Auth EMV-CAP", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A0000000049999", "Vendor": "Mastercard International", "Country": "United States", "Name": "MasterCard PayPass??", "PaymentSchemeIdentifier": "MASTERCARD" },
    { "AID": "A000000025", "Vendor": "American Express", "Country": "United Kingdom", "Name": "American Express", "PaymentSchemeIdentifier": "AMEX" },
    { "AID": "A0000000250000", "Vendor": "American Express", "Country": "United Kingdom", "Name": "American Express", "PaymentSchemeIdentifier": "AMEX" },
    { "AID": "A00000002501", "Vendor": "American Express", "Country": "United Kingdom", "Name": "American Express", "PaymentSchemeIdentifier": "AMEX" },
    { "AID": "A000000025010402", "Vendor": "American Express", "Country": "United Kingdom", "Name": "American Express", "PaymentSchemeIdentifier": "AMEX" },
    { "AID": "A000000025010701", "Vendor": "American Express", "Country": "United Kingdom", "Name": "ExpressPay", "PaymentSchemeIdentifier": "AMEX" },
    { "AID": "A000000025010801", "Vendor": "American Express", "Country": "United Kingdom", "Name": "American Express", "PaymentSchemeIdentifier": "AMEX" },
    { "AID": "A000000098", "Vendor": "Visa USA", "Country": "United States", "Name": "Debit Card", "PaymentSchemeIdentifier": "VISA" },
    { "AID": "A0000000980848", "Vendor": "Visa USA", "Country": "United States", "Name": "Debit Card", "PaymentSchemeIdentifier": "VISA" },
  ];
  cardSubTypeTable = [
    { "Type": "DEBIT"},
    { "Type": "CREDIT"},
    { "Type": "Pre-paid"},
  ];
  form = {
    'hash': 'securityToken',
    name: '',
    cardNumber: '',
    additionalSecurityTokens: [],
    merchantId: "fakeMerchantId",
    surrogatePAN: "",
    expiryMonth: '0',
    expiryYear: (new Date().getFullYear()).toString().substr(-2),
    cvv: '',
    currentYear: (new Date().getFullYear()).toString().substr(-2),
    yearMinus4: (new Date().getFullYear().valueOf() + (- 4)).toString().substr(-2),
    yearMinus3: (new Date().getFullYear().valueOf() + (-3)).toString().substr(-2),
    yearMinus2: (new Date().getFullYear().valueOf() + (-2)).toString().substr(-2),
    yearMinus1: (new Date().getFullYear().valueOf() + (-1)).toString().substr(-2),
    year1: (new Date().getFullYear().valueOf() + 1).toString().substr(-2),
    year2: (new Date().getFullYear().valueOf() + 2).toString().substr(-2),
    year3: (new Date().getFullYear().valueOf() + 3).toString().substr(-2),
    year4: (new Date().getFullYear().valueOf() + 4).toString().substr(-2),
    year5: (new Date().getFullYear().valueOf() + 5).toString().substr(-2),
    year6: (new Date().getFullYear().valueOf() + 6).toString().substr(-2),
    year7: (new Date().getFullYear().valueOf() + 7).toString().substr(-2),
    year8: (new Date().getFullYear().valueOf() + 8).toString().substr(-2),
    issuerCountryCode: '',
    paymentAccountReference: '',
    transactionCurrencyCode: '',
    paymentSchemeIdentifier: '',
    cardSubType: ''
  };

  params = {
    cancelUrl: "",
    successUrl: "",
    tokenizationSessionId: ""
  };

  constructor(private activatedRoute: ActivatedRoute, private httpService: ApiHttpService) {
  }


  ngOnInit(): void {
  }

  // tslint:disable-next-line:typedef
  changeSwitch() {
    this.form.additionalSecurityTokens = [];
  }

  // tslint:disable-next-line:typedef
  clearAlert() {
    this.alert = "";
  }

  // tslint:disable-next-line:typedef
  updateSurrogatePan() {
    if (this.form.hash === "securityToken") {
      this.form.surrogatePAN = this.cryptoService.hmacSha256(this.form.cardNumber);
    } else if (this.form.hash === "sha256") {
      this.form.surrogatePAN = this.cryptoService.sha256(this.form.cardNumber);
    } else if (this.form.hash === "hmacSha256") {
      this.form.surrogatePAN = this.cryptoService.hmacSha256(this.form.cardNumber);
    } else if (this.form.hash === 'standard') {
      this.form.surrogatePAN = this.cryptoService.standard(this.form.cardNumber);
    }
  }


  // tslint:disable-next-line:typedef
  provisionMedia() {

    let data: {
      creditCardNumber?: string,
      surrogatePAN?: string,
      merchantId?: string,
      expiryDate?: Date,
      issuerCountryCode?: string,
      cvv?: string,
      transactionCurrencyCode?: string,
      paymentSchemeIdentifier?: string,
      paymentAccountReference?: string,
      cardSubType?: string,
    };
    data = {
      creditCardNumber: this.form.cardNumber,
      surrogatePAN: this.form.surrogatePAN,
      merchantId: this.form.merchantId,
      // tslint:disable-next-line:max-line-length
      expiryDate: new Date(Number(((new Date().getFullYear()).toString().substr(0, 2)).concat(this.form.expiryYear)), Number(this.form.expiryMonth) + 1, 0 + 1),
      issuerCountryCode: this.form.issuerCountryCode,
      cvv: this.form.cvv,
      transactionCurrencyCode: this.form.transactionCurrencyCode,
      paymentSchemeIdentifier: this.form.paymentSchemeIdentifier,
      paymentAccountReference: this.form.paymentAccountReference,
      cardSubType: this.form.cardSubType,
    };

    return this.httpService.getOrCreateSecurityToken(data)
      .pipe()
      .subscribe((data) => {
          this.successAlert("Configuration Updated successfully");
        },
        (error) => {
          this.failAlert("Error while processing");
        });
  }

  // tslint:disable-next-line:typedef
  successAlert(message) {
    this.alert = "OK";
    this.alertMessage = message;
    setTimeout(() => {
      this.clearAlert();
    }, 3000);
  }

  // tslint:disable-next-line:typedef
  failAlert(message) {
    this.alert = "KO";
    this.alertMessage = message;
    setTimeout(() => {
      this.clearAlert();
    }, 3000);
  }

  // tslint:disable-next-line:typedef
  checkLength(e, input, size, max) {
    const functionalKeys = ['Backspace', 'ArrowRight', 'ArrowLeft'];


    if (functionalKeys.indexOf(e.key) !== -1) {
      return;
    }

    const keyValue = +e.key;
    if (isNaN(keyValue)) {
      e.preventDefault();
      return;
    }

    const hasSelection = input.selectionStart !== input.selectionEnd && input.selectionStart !== null;
    let newValue;
    if (hasSelection) {
      newValue = this.replaceSelection(input, e.key)
    } else {
      newValue = input.value + keyValue.toString();
    }

    if (+newValue > max || newValue.length > size) {
      e.preventDefault();
    }
  }

  private replaceSelection(input, key) {
    const inputValue = input.value;
    const start = input.selectionStart;
    const end = input.selectionEnd || input.selectionStart;
    return inputValue.substring(0, start) + key + inputValue.substring(end + 1);
  }
}
